import CustonMixins from '@/mixins/CustonMixins'
import { createClient } from '@supabase/supabase-js'
import DBCrudCacheSet from '@/models/DBCrudCacheSet'

class Helpers {
    static isMobile() {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            return true
        } else {
            return false
        }
    }
    static snakeToTitle(string) {
        return string
            .split('_')
            .map(
                (part) =>
                    part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
            )
            .join(' ')
    }

    static mapPlaceFields() {
        return [
            // {
            //     flag: 'mapName',
            //     googleType: 'simple',
            //     googleName: 'mapName',
            // },
            {
                flag: 'mapExtraFormattedAddress',
                googleType: 'simple',
                googleName: 'formatted_address',
            },
            {
                flag: 'mapExtraPlaceID',
                googleType: 'simple',
                googleName: 'place_id',
            },
            {
                flag: 'mapExtraGeoLocLong',
                googleType: 'mapGeoLoc',
                googleName: 'lng',
            },
            {
                flag: 'mapExtraGeoLocLat',
                googleType: 'mapGeoLoc',
                googleName: 'lat',
            },
            {
                flag: 'relForeignKeyMapExtraRelCountry',
                googleType: 'components',
                googleName: 'country',
            },
            {
                flag: 'relForeignKeyMapExtraRelAdminArea1',
                googleType: 'components',
                googleName: 'administrative_area_level_1',
            },
            {
                flag: 'relForeignKeyMapExtraRelAdminArea2',
                googleType: 'components',
                googleName: 'administrative_area_level_2',
            },
            {
                flag: 'relForeignKeyMapExtraRelLocality',
                googleType: 'components',
                googleName: 'locality',
            },
            // {
            //     flag: 'mapExtraRelSublocality1',
            //     googleType: 'components',
            //     googleName: 'sublocality_level_1',
            // },
            // {
            //     flag: 'mapExtraRelSublocality2',
            //     googleType: 'components',
            //     googleName: 'sublocality_level_2',
            // },
            {
                flag: 'relForeignKeyMapExtraRelSublocality',
                googleType: 'components',
                googleName: ['sublocality_level_1', 'sublocality_level_2'], // Search for both sublocality levels
            },
        ]
    }

    static getIfMatchesAllChecks(item, filters) {
        for (const [key, filter] of Object.entries(filters)) {
            // todo: note the below logic was to support time range filters
            if (
                typeof filter === 'object' &&
                !Array.isArray(filter) &&
                filter !== null
            ) {
                if (filter?.value) {
                    if (filter.usageType === 'timeRangeStart') {
                        if (filter.value.range.start) {
                            const startDate = new Date(filter.value.range.start)
                            const endDate = new Date(filter.value.range.end)
                            const itemDate = new Date(item[key])
                            return startDate < itemDate && itemDate < endDate
                        }
                    }
                }
            } else if (filter !== null) {
                return item[key] == filter
            }
        }

        return true
    }

    static prepareFiltersForAxios(obj) {
        let result = []
        for (const [key, filter] of Object.entries(obj)) {
            // todo: note the below logic was to support time range filters
            if (
                typeof filter === 'object' &&
                !Array.isArray(filter) &&
                filter !== null
            ) {
                if (filter?.value) {
                    if (filter.usageType === 'timeRangeStart') {
                        if (filter.value.range.start) {
                            result.push(
                                `${key}=gte.${filter.value.range.start}`
                            )
                            result.push(`${key}=lte.${filter.value.range.end}`)
                        }
                    }
                }
            } else if (filter !== null) {
                // result[key] = `eq.${item.value}`;
                result.push(`${key}=eq.${filter}`)
            }
        }
        // return result;
        return result.join('&')
    }

    static prepareRelationsForAxios(arr) {
        let select = ['*']
        for (const value of arr) {
            select.push(`${value}(*)`)
        }

        // *,event_type_id(*),provider_that_owns_this_id(*),event_type:venue_country_id(*),venue_state_id(*),venue_substate_id(*),venue_town_id(*),venue_suburb_id(*)
        const result = select.join(',')

        return {
            select: result,
        }
    }
    static computedAttrs(model, excludedCols = []) {
        let crudCache = DBCrudCacheSet.find(model.entity)
        if (!crudCache) {
            DBCrudCacheSet.Generate(model)
            crudCache = DBCrudCacheSet.find(model.entity)
        }
        const result = Helpers.computedAttrs2(crudCache.fields, excludedCols)
        return result
    }
    static computedAttrs2(fields, excludedCols = []) {
        let lookupKeys = [] // To keep track of foreign keys

        let result = []

        for (const field of fields) {
            // if (!excludedCols.includes(field.name)) {
            //     if (field.usageType.startsWith('relForeignKey')) {
            //         if (!excludedCols.includes(field.meta.foreignKey)) {
            //             result.push(field)
            //             lookupKeys.push(field)
            //         }
            //     } else {
            //         result.push(field)
            //     }
            // }

            excludedCols
            if (field.usageType.startsWith('relForeignKey')) {
                result.push(field)
                lookupKeys.push(field)
            } else {
                result.push(field)
            }
        }

        return result
    }

    static SupaerTableHeaders(
        model,
        excludedCols = [],
        canEdit,
        displayMapField = false
    ) {
        let result = []
        const computedAttrs = this.computedAttrs(model, excludedCols)

        for (const computedAttr of computedAttrs) {
            if (
                // !computedAttr.dataType.startsWith('mapExtra') &&
                // computedAttr.dataType !== 'foreignKey'
                computedAttr.usageType.startsWith('relForeignKey')
            ) {
                // do nothing
            } else if (computedAttr.usageType.startsWith('relLookup')) {

                const relatedAttrs = this.computedAttrs(
                    computedAttr.meta.relatedModel,
                    excludedCols
                )
                let children = []
                for (const relatedAttr of relatedAttrs) {
                    if (relatedAttr.important == true){
                        if (relatedAttr.usageType.startsWith('relForeignKey')) {
                            // do nothing
                        } else {
                            children.push({
                                text: relatedAttr.label,
                                value: relatedAttr.name,
                                usageType: relatedAttr.usageType,
                                dataType: relatedAttr.dataType,
                                meta: relatedAttr.meta,
                            })
                        }
                    }
                }

                result.push({
                    text: computedAttr.label,
                    value: computedAttr.name,
                    usageType: computedAttr.usageType,
                    dataType: computedAttr.dataType,
                    meta: computedAttr.meta,
                    children: children,
                })
            } else {
                result.push({
                    text: computedAttr.label,
                    value: computedAttr.name,
                    usageType: computedAttr.usageType,
                    dataType: computedAttr.dataType,
                    meta: computedAttr.meta,
                })
            }
        }
        if (canEdit) {
            result.push({
                text: 'Actions',
                value: 'actions',
                usageType: 'actions',
                dataType: 'actions',
            })
        }

        result = result.filter((item) => {
            if (displayMapField) {
                if (!item.usageType.startsWith('relForeignKey')) {
                    return true
                }
            } else {
                return (
                    !item.usageType.startsWith('relForeignKey') &&
                    !item.usageType.startsWith('relLookupMapExtra') &&
                    !item.usageType.startsWith('mapExtra')
                )
            }
        })
        // console.log(result)

        return result
    }

    static getSupabaseClient() {
        const baseUrlAndHeaders =
            CustonMixins.methods.DefaultHeadersAndBaseUrl()
        const result = createClient(
            baseUrlAndHeaders.baseURL,
            baseUrlAndHeaders.headers.AuthToken,
            {
                headers: { ...baseUrlAndHeaders.headers },
            }
        )
        return result
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    static preparePayload(input, modelFields) {
        let result = []
        for (const attr of modelFields) {
            if (typeof input?.[attr.name] !== 'undefined') {
                if (attr.usageType.startsWith('relForeignKey')) {
                    result[attr.meta.foreignKey] = input[attr.meta.foreignKey]
                } else if (attr.usageType.startsWith('relLookup')) {
                    // do nothing
                } else if (attr.usageType.startsWith('relChildren')) {
                    // do nothing
                } else if (
                    [
                        'timestampType',
                        'readOnlyTimestampType',
                        'timeRangeStart',
                        'timeRangeEnd',
                    ].includes(attr.usageType)
                ) {
                    if (input[attr.name]) {
                        result[attr.name] = input[attr.name]
                    }
                } else {
                    result[attr.name] = input[attr.name]
                }
            }
        }
        delete result.$id
        return result
    }
}

export default Helpers
