<template>
    <v-app id="inspire">
        <!--      <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet">-->

        <MenuSystem>
            <template v-slot:main>
                <router-view></router-view>
                <SnackbarNotifications />
            </template>
        </MenuSystem>
    </v-app>
</template>

<script>
import LoginSession from '@/models/LoginSession'
import SnackbarNotifications from '@/views/global/SnackbarNotifications'
import MenuSystem from '@/views/global/MenuSystem.vue'
import moment from 'moment'
import VueCookies from 'vue-cookies'
import SnackbarNotification from '@/models/SnackbarNotification'
import DBPerson from '@/models/DBPerson'
import DBProviderGroup from '@/models/DBProviderGroup'
import DBCustomerGroup from '@/models/DBCustomerGroup'
import DBCustomerManager from '@/models/DBCustomerManager'

export default {
    name: 'App',
    components: {
        SnackbarNotifications,
        MenuSystem,
    },
    data() {
        return {
            tokenTimeout: null,
        }
    },
    watch: {
        loginSessionCount(newCount, oldCount) {
            if (newCount > oldCount) {
                this.monitorTokenExpiry()
            }
        },
        'loginSession.access_token'() {
            setTimeout(() => {
                this.fetchProviderGroupForOwners()
                // this.fetchProviderGroupForManagers()
                this.fetchCustomerGroupsForOwners()
                this.fetchCustomerGroupForManagers()
            }, 300)
        },
    },
    computed: {
        loginSessionCount() {
            return LoginSession.query().count()
        },
    },
    methods: {
        fetchProviderGroupForOwners() {
            DBProviderGroup.FetchAll({
                page: 1,
                limit: 100,
                filters: this.ownerFilters,
                flags: {},
                moreHeaders: {},
                clearPrimaryModelOnly: false,
                relationships: [],
            }).then(() => {})
        },
        // fetchProviderGroupForManagers() {
        //     DBCustomerManager.FetchAll({
        //         page: 1,
        //         limit: 100,
        //         filters: this.managerFilters,
        //         flags: {},
        //         moreHeaders: {},
        //         clearPrimaryModelOnly: false,
        //         relationships: [],
        //     }).then(() => {})
        //
        //     // DBMembership.FetchAll({
        //     //     page: 1,
        //     //     limit: 100,
        //     //     filters: this.managerFilters,
        //     //     flags: {},
        //     //     moreHeaders: {},
        //     //     clearPrimaryModelOnly: false,
        //     // relationships: [],
        //     // }).then(() => {})
        // },

        fetchCustomerGroupsForOwners() {
            DBCustomerGroup.FetchAll({
                page: 1,
                limit: 100,
                filters: this.ownerFilters,
                flags: {},
                moreHeaders: {},
                clearPrimaryModelOnly: false,
                relationships: [],
            }).then(() => {})
        },
        fetchCustomerGroupForManagers() {
            DBCustomerManager.FetchAll({
                page: 1,
                limit: 100,
                filters: this.managerFilters,
                flags: {},
                moreHeaders: {},
                clearPrimaryModelOnly: false,
                relationships: [],
            }).then(() => {})
        },
        monitorTokenExpiry() {
            const loginSessionEntity = LoginSession.query().with('user').first()

            if (
                loginSessionEntity &&
                loginSessionEntity.expires_in &&
                loginSessionEntity.user.last_sign_in_at
            ) {
                const lastSignInDate = moment(
                    loginSessionEntity.user.last_sign_in_at
                )

                const tokenExpireDate = lastSignInDate
                    .clone()
                    .add(loginSessionEntity.expires_in, 'seconds')

                const millisTillExpiry = tokenExpireDate
                    .subtract(1, 'minutes')
                    .diff(moment())

                if (this.tokenTimeout) {
                    clearTimeout(this.tokenTimeout)
                }

                let duration = moment.duration(millisTillExpiry)
                let hours = Math.floor(duration.asHours())
                let minutes = Math.floor(duration.asMinutes()) - hours * 60
                let seconds = duration.seconds()

                console.log(
                    `LoginSession will expire in: ${hours} hours, ${minutes} minutes, and ${seconds} seconds`
                )

                this.tokenTimeout = setTimeout(() => {
                    SnackbarNotification.insert({
                        data: {
                            id: Date.now(),
                            text: 'Your login LoginSession has expired.',
                            date: moment().toISOString(),
                            show: true,
                        },
                    })
                    VueCookies.remove('VUE_APP_AUTH')
                    LoginSession.deleteAll()
                }, millisTillExpiry)
            }
        },
    },
    mounted() {
        const VUE_APP_AUTH = this.$cookies.get('VUE_APP_AUTH')

        if (VUE_APP_AUTH) {
            LoginSession.deleteAll()
            LoginSession.insert({
                data: VUE_APP_AUTH,
            })
            DBPerson.FetchAll({
                page: 1,
                limit: 1,
                filters: {
                    user_id: this.loginSession.user_id,
                },
                flags: {},
                moreHeaders: {},
                clearPrimaryModelOnly: false,
                relationships: [],
            })

            this.fetchProviderGroupForOwners()
            // this.fetchProviderGroupForManagers()
            this.fetchCustomerGroupsForOwners()
            this.fetchCustomerGroupForManagers()
        }
    },
    beforeDestroy() {
        if (this.tokenTimeout) {
            clearTimeout(this.tokenTimeout)
        }
    },
}
</script>
