import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/sign-in-up',
        name: '/sign-in-up',
        component: () =>
            import(
                /* webpackChunkName: "/all-contnent" */ '../views/DefaultVue.vue'
            ),
        children: [
            {
                path: '/sign-in',
                name: '/sign-in',
                component: () => import('@/views/misc/SigninView.vue'),
            },
            {
                path: '/avi-stress-test',
                name: '/avi-stress-test',
                component: () => import('@/views/tests/AviStressTest.vue'),
            },
            {
                path: '/join',
                name: '/join',
                component: () => import('@/views/misc/JoinView.vue'),
            },
        ],
    },
    {
        path: '/',
        name: '/',
        component: () =>
            import(
                /* webpackChunkName: "/all-contnent" */ '../views/DefaultVue.vue'
            ),
        children: [
            {
                path: '/',
                name: '/',
                component: () =>
                    import(
                        /* webpackChunkName: "/all-contnent" */ '@/views/lists/ListsIntroduction.vue'
                    ),
            },
            {
                path: '/lists/providers',
                name: '/lists/providers',
                component: () =>
                    import(
                        '@/views/lists/provider-groups/ListsProviderGroups.vue'
                    ),
            },
            {
                path: '/lists/providers/:cId',
                name: '/lists/providers/:cId',
                component: () =>
                    import(
                        /* webpackChunkName: "/all-contnent" */ '@/views/lists/provider-groups/ListsProviderGroupsRead.vue'
                    ),
            },
            {
                path: '/lists/customers',
                name: '/lists/customers',
                component: () =>
                    import(
                        '@/views/lists/customer-groups/ListsCustomerGroups.vue'
                    ),
            },
            {
                path: '/lists/customers/:cId',
                name: '/lists/customers/:cId',
                component: () =>
                    import(
                        /* webpackChunkName: "/all-contnent" */ '@/views/lists/customer-groups/ListsCustomerGroupsRead.vue'
                    ),
            },
            {
                path: '/lists/events',
                name: '/lists/events',
                component: () =>
                    import(
                        /* webpackChunkName: "/all-contnent" */ '@/views/lists/events/ListsEvents.vue'
                    ),
            },
            {
                path: '/lists/events/:cId',
                name: '/lists/events/:cId',
                component: () =>
                    import(
                        /* webpackChunkName: "/all-contnent" */ '@/views/lists/events/ListsEventsRead.vue'
                    ),
            },
            {
                path: '/menu',
                name: 'menu',
                component: () => import('../views/MenuView.vue'),
            },
        ],
    },
    {
        path: '/account',
        name: '/account',
        redirect: '/account/introduction',
        component: () =>
            import(
                /* webpackChunkName: "/all-contnent" */ '../views/DefaultVue.vue'
            ),
        children: [],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
