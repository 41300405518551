import DBProviderGroup from '@/models/DBProviderGroup'
import DBCustomerGroup from '@/models/DBCustomerGroup'
import DBBaseModel from '@/models/DBBaseModel'
import FieldUsageTypes from '@/utils/FieldUsageTypes'
import DBPerson from '@/models/DBPerson'

export default class DBMembership extends DBBaseModel {
    static entity = 'DBMembership'

    static fieldsMetadata = {
        approved_by_id: {
            usageType: FieldUsageTypes.ownerApprovedByProviderType(),
        },
        customer_id: {
            usageType: FieldUsageTypes.ownerCustomerType(),
        },
        provider_being_applied_to_id: {
            usageType: FieldUsageTypes.ownerAppliedToProviderType(),
        },
        person_that_created_this: this.belongsTo(
            DBPerson,
            'person_that_created_this_id'
        ),
        created_at: { usageType: FieldUsageTypes.readOnlyTimestampType() },
        person_that_created_this_id: {
            usageType: FieldUsageTypes.creatorType(),
        },
    }

    static fields() {
        return {
            id: this.uid(null),

            approved_by_id: this.attr(null).nullable(),
            approved_by: this.belongsTo(DBProviderGroup, 'approved_by_id'),
            provider_being_applied_to_id: this.attr(null).nullable(),
            provider_being_applied_to: this.belongsTo(
                DBProviderGroup,
                'provider_being_applied_to_id'
            ),
            customer_id: this.attr(null),
            customer: this.belongsTo(DBCustomerGroup, 'customer_id'),
            created_at: this.attr('').nullable(),
            person_that_created_this_id: this.attr(null),
            person_that_created_this: this.belongsTo(
                DBPerson,
                'person_that_created_this_id'
            ),
        }
    }

    static FetchAll(
        options = {
            page: 1,
            limit: 15,
            filters: {},
            flags: {},
            moreHeaders: {},
            clearPrimaryModelOnly: false,
            memberships: [],
        }
    ) {
        return this.customSupabaseApiFetchAll(
            `/rest/v1/memberships`,
            ['approved_by:approved_by_id', 'customer:customer_id'],
            options
        )
    }

    static FetchById(id, memberships = []) {
        memberships
        return this.customSupabaseApiFetchById(
            `/rest/v1/memberships?id=eq.${id}&select=*`,
            id,
            memberships
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(
            `/rest/v1/memberships?select=*`,
            entity
        )
    }

    static Update(entity) {
        return this.customSupabaseApiUpdate(
            `/rest/v1/memberships?id=eq.${entity.id}&select=*`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/memberships?id=eq.${entityId}`,
            entityId
        )
    }
}
