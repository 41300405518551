import DBPerson from '@/models/DBPerson'
import DBCustomerGroup from '@/models/DBCustomerGroup'
import DBBaseModel from '@/models/DBBaseModel'
import FieldUsageTypes from '@/utils/FieldUsageTypes'

export default class DBCustomerManager extends DBBaseModel {
    static entity = 'DBCustomerManager'

    static fieldsMetadata = {
        created_at: { usageType: FieldUsageTypes.readOnlyTimestampType() },
        customer_that_owns_this_id: {
            usageType: FieldUsageTypes.ownerProviderType(),
        },
    }

    static fields() {
        return {
            id: this.uid(null),
            member_id: this.attr(null).nullable(),
            member: this.belongsTo(DBPerson, 'member_id'),
            created_at: this.attr('').nullable(),

            customer_that_owns_this_id: this.attr(null),
            customer_that_owns_this: this.belongsTo(
                DBCustomerGroup,
                'customer_that_owns_this_id'
            ),
        }
    }

    static FetchAll(
        options = {
            page: 1,
            limit: 15,
            filters: {},
            flags: {},
            moreHeaders: {},
            clearPrimaryModelOnly: false,
            relationships: [],
        }
    ) {
        return this.customSupabaseApiFetchAll(
            `/rest/v1/customer_managers`,
            // [
            //     'customer_that_owns_this:customer_that_owns_this_id(*, memberships(*))',
            // ],
            [
                'customer_that_owns_this:customer_that_owns_this_id(*, memberships(*, approved_by:approved_by_id(*)))',
            ],
            options
        )
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/customer_managers?id=eq.${id}&select=*`,
            id,
            relationships
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(
            `/rest/v1/customer_managers?select=*`,
            entity
        )
    }

    static Update(entity) {
        return this.customSupabaseApiUpdate(
            `/rest/v1/customer_managers?id=eq.${entity.id}&select=*`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/customer_managers?id=eq.${entityId}`,
            entityId
        )
    }
}
