import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import CustonMixins from '@/mixins/CustonMixins'
import VueCookies from 'vue-cookies'
import VueGeolocation from 'vue-browser-geolocation/src'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'

Vue.config.productionTip = false

Vue.component('GmapCluster', GmapCluster)
Vue.mixin(CustonMixins)
Vue.use(VueCookies, { expires: '7d' })
Vue.use(VueGeolocation)
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY,
        libraries: 'places',
    },
    installComponents: true,
})

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app')
