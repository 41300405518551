import DBBaseModel from '@/models/DBBaseModel'
import FieldUsageTypes from '@/utils/FieldUsageTypes'

export default class DBPerson extends DBBaseModel {
    static entity = 'DBPerson'

    static fieldsMetadata = {
        created_at: { usageType: FieldUsageTypes.readOnlyTimestampType() },
    }
    static fields() {
        return {
            id: this.uid(null),
            first_name: this.attr(''),
            last_name: this.attr(''),
            created_at: this.attr('').nullable(),
            user_id: this.attr(null).nullable(),
        }
    }

    static FetchAll(options) {
        return this.customSupabaseApiFetchAll(`/rest/v1/persons`, [], options)
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/persons?id=eq.${id}&select=*`,
            id,
            relationships
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(`/rest/v1/persons?select=*`, entity)
    }

    static Update(entity) {
        return this.customSupabaseApiUpdate(
            `/rest/v1/persons?id=eq.${entity.id}&select=*`,
            entity
        )
    }

    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/persons?id=eq.${entityId}`,
            entityId
        )
    }
}
