import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
// import VueCookies from 'vue-cookies'

// Register Models to Database.

import User from '@/models/User'

import LoginSession from '@/models/LoginSession'
import Post from '@/models/Post'
import Food from '@/models/Food'
import Upload from '@/models/Upload'
import UploadRelation from '@/models/UploadRelation'
import place from '@/models/place'
import DBEvent from '@/models/DBEvent'
import DBEventType from '@/models/DBEventType'
import DBMembership from '@/models/DBMembership'
import order from '@/models/order'
import DBProviderGroup from '@/models/DBProviderGroup'
import SnackbarNotification from '@/models/SnackbarNotification'
import Image from '@/models/Image'
import Table from '@/models/Table'
import DBVenueCountry from '@/models/DBVenueCountry'
import DBVenueState from '@/models/DBVenueState'
import DBVenueSubstate from '@/models/DBVenueSubstate'
import DBVenueTown from '@/models/DBVenueTown'
import DBVenueSuburb from '@/models/DBVenueSuburb'
import DBPerson from '@/models/DBPerson'
import DBCrudCacheSet from '@/models/DBCrudCacheSet'
import DBCustomerGroup from '@/models/DBCustomerGroup'
import DBCustomerManager from '@/models/DBCustomerManager'
import DBRSVP from '@/models/DBRSVP'

Vue.use(Vuex)

// const VUE_APP_AUTH = VueCookies.get('VUE_APP_AUTH')
//
// let headers = {
//     Apikey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyAgCiAgICAicm9sZSI6ICJhbm9uIiwKICAgICJpc3MiOiAic3VwYWJhc2UtZGVtbyIsCiAgICAiaWF0IjogMTY0MTc2OTIwMCwKICAgICJleHAiOiAxNzk5NTM1NjAwCn0.dc_X5iR_VP_qT0zsiyj_I_OZ2T9FtRU2BBNWN8Bu4GE',
//     'Content-Type': 'application/json',
// }
// const supabaseUrl = 'http://localhost:8000'
// const apiUrl = `${supabaseUrl}/rest/v1`
//
// if (VUE_APP_AUTH?.jwt) {
//     headers = {
//         ...headers,
//         Authorization: 'Bearer ' + VUE_APP_AUTH.jwt,
//     }
// }

VuexORM.use(VuexORMAxios, {
    axios,
    // headers,
    // baseURL: apiUrl,
})

// Create a new instance of Database.
const database = new VuexORM.Database()
database.register(DBCrudCacheSet)
database.register(User)
database.register(LoginSession)
database.register(Post)
database.register(Food)
database.register(Upload)
database.register(UploadRelation)
database.register(place)
database.register(DBEvent)
database.register(DBEventType)
database.register(DBMembership)
database.register(order)
database.register(DBProviderGroup)
database.register(SnackbarNotification)
database.register(Table)
database.register(Image)
database.register(DBVenueCountry)
database.register(DBVenueState)
database.register(DBVenueSubstate)
database.register(DBVenueTown)
database.register(DBVenueSuburb)
database.register(DBPerson)
database.register(DBCustomerGroup)
database.register(DBCustomerManager)
database.register(DBRSVP)

// Create Vuex Store and register database through Vuex ORM.
const store = new Vuex.Store({
    plugins: [VuexORM.install(database)],
})

export default store
