// Auth Model

import { Model } from '@vuex-orm/core'
import CustonMixins from '@/mixins/CustonMixins'
import User from '@/models/User'

export default class LoginSession extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'login-sessions'
    static primaryKey = 'access_token'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            access_token: this.attr(''),
            token_type: this.attr(''),
            expires_in: this.attr(null),
            refresh_token: this.attr(''),
            user_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'),
            enabled_provider_group_id: this.attr(null),
            enabled_customer_group_id: this.attr(null),
        }
    }

    static Login(form) {
        return this.api()
            .post(
                '/auth/v1/token?grant_type=password',
                {
                    email: form.email,
                    password: form.password,
                },
                {
                    ...CustonMixins.methods.DefaultHeadersAndBaseUrl(),
                    dataTransformer: ({ data }) => {
                        const result =
                            CustonMixins.methods.NormalizeRecursive(data)
                        return result
                    },
                }
            )
            .then((res) => {
                CustonMixins.methods.logNetworkSuccess(res)
                return res
            })
            .catch((error) => {
                CustonMixins.methods.logNetworkError(error)
            })
    }
}
