<template>
    <v-select
        :label="`Active Provider Group`"
        :value="value"
        @input="
            (e) => {
                $emit('input', e)
            }
        "
        item-text="name"
        item-value="id"
        :items="providerGroupsAssociatedWithUser"
        :readonly="readonly"
        :disabled="readonly"
        :rules="rules"
    ></v-select>
</template>
<script>
export default {
    name: 'SelectAssociatedProvider',

    props: {
        value: {
            type: [Number, Object],
            default() {
                return null
            },
        },
        readonly: {
            type: Boolean,
            default() {
                return false
            },
        },
        rules: {
            type: Array,
            default() {
                return []
            },
        },
    },
}
</script>

<style scoped></style>
