import FieldUsageTypes from '@/utils/FieldUsageTypes'
import CustonMixins from '@/mixins/CustonMixins'
import { createClient } from '@supabase/supabase-js'
import DBBaseModel from '@/models/DBBaseModel'
import DBCustomerGroup from '@/models/DBCustomerGroup'
import DBEvent from '@/models/DBEvent'
import DBProviderGroup from '@/models/DBProviderGroup'

export default class DBRSVP extends DBBaseModel {
    static entity = 'DBRSVP'

    static getSupabaseClient() {
        const baseUrlAndHeaders =
            CustonMixins.methods.DefaultHeadersAndBaseUrl()
        const result = createClient(
            baseUrlAndHeaders.baseURL,
            baseUrlAndHeaders.headers.AuthToken,
            {
                headers: { ...baseUrlAndHeaders.headers },
            }
        )
        return result
    }

    static fieldsMetadata = {
        customer_id: {
            usageType: FieldUsageTypes.ownerCustomerType(),
        },
        provider_that_owns_this_id: {
            usageType: FieldUsageTypes.ownerProviderType(),
        },
        created_at: { usageType: FieldUsageTypes.readOnlyTimestampType() },
    }

    static fields() {
        return {
            id: this.uid(null),

            event_id: this.attr(null).nullable(),
            event: this.belongsTo(DBEvent, 'event_id'),

            customer_id: this.attr(null),
            customer: this.belongsTo(DBCustomerGroup, 'customer_id'),

            provider_that_owns_this_id: this.attr(null),
            provider_that_owns_this: this.belongsTo(
                DBProviderGroup,
                'provider_that_owns_this_id'
            ),

            created_at: this.attr(''),
        }
    }

    static parentRels = [
        'provider_that_owns_this:provider_that_owns_this_id',
        'customer:customer_id',
        'event:event_id',
    ]

    static FetchAll(
        options = {
            page: 1,
            limit: 15,
            filters: {},
            flags: {},
            moreHeaders: {},
            clearPrimaryModelOnly: false,
            relationships: [],
        }
        // { page = 1, limit = 15 },
        // filters = {},
        // flags = {},
        // relationships = [],
        // moreHeaders = {},
        // clearPrimaryModelOnly = false
    ) {
        // const supabase = this.getSupabaseClient()
        // supabase
        //     .from('events')
        //     .select('*,*', { count: 'exact'})
        //     .then((res) => {
        //         console.log(res)
        //     })

        // const test2 = supabase
        //     .from('events')
        //     .select().then().catch()
        // console.log(test2)

        // relationships
        return this.customSupabaseApiFetchAll(
            `/rest/v1/rsvps`,
            this.parentRels,
            options
        )
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/rsvps?id=eq.${id}`,
            id,
            this.parentRels
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(
            `/rest/v1/rsvps?select=*`,
            entity
        )
    }

    static Update(entity) {
        // const supabase = this.getSupabaseClient()
        //
        // let payload = {...entity}
        // // delete payload.id
        // // delete payload.created_at
        // supabase
        //     .from('rsvps')
        //     .update({...payload})
        //     .eq('id', entity.id)
        //     .select()
        //     .then().catch()

        return this.customSupabaseApiUpdate(
            `/rest/v1/rsvps?id=eq.${entity.id}&select=*`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/rsvps?id=eq.${entityId}`,
            entityId
        )
    }
}
