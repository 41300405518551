import DBEvent from '@/models/DBEvent'
import DBBaseModel from '@/models/DBBaseModel'
import FieldUsageTypes from '@/utils/FieldUsageTypes'

export default class DBEventType extends DBBaseModel {
    static entity = 'DBEventTypes'

    static fieldsMetadata = {
        created_at: { usageType: FieldUsageTypes.readOnlyTimestampType() },
    }

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            created_at: this.attr(''),
            backColor: this.attr(''),
            borderColor: this.attr(''),
            events: this.hasMany(DBEvent, 'event_type_id'),

            // id: this.uid(null),
            // place: this.attr(null),
            // text: this.attr(''),
            // // // start: this.attr('[2023-09-05T18:00:00.000Z, 2023-09-05T22:00:00.000Z]'),
            // // // start: this.attr(''),
            // start: this.attr(''),
            // end: this.attr(''),
            // event_type_id: this.attr(null),
            // event_type: this.belongsTo(DBEventType, 'event_type_id'),
        }
    }

    static FetchAll(options) {
        return this.customSupabaseApiFetchAll(
            `/rest/v1/event_types`,
            [],
            options
        )
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/event_types?id=eq.${id}&select=*`,
            id,
            relationships
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(
            `/rest/v1/event_types?select=*`,
            entity
        )
    }

    static Update(entity) {
        return this.customSupabaseApiUpdate(
            `/rest/v1/event_types?id=eq.${entity.id}&select=*`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/event_types?id=eq.${entityId}`,
            entityId
        )
    }
}
