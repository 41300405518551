import DBBaseModel from '@/models/DBBaseModel'
import DBVenueTown from '@/models/DBVenueTown'
// import DBVenueTown  from '@/models/DBVenueTown'

export default class DBVenueSuburb extends DBBaseModel {
    static entity = 'DBVenueSuburb'

    static fields() {
        return {
            id: this.uid(null),
            name: this.attr(''),
            parent_id: this.attr(null),
            venue_town: this.belongsTo(DBVenueTown, 'parent_id'),
            // events: this.hasMany(DBEvent, 'event_type_id'),
        }
    }

    static FetchAll(options) {
        return this.customSupabaseApiFetchAll(
            `/rest/v1/venue_suburbs`,
            [],
            options
        )
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/venue_suburbs?id=eq.${id}&select=*`,
            id,
            relationships
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(
            `/rest/v1/venue_suburbs?select=*`,
            entity
        )
    }

    static Upsert(entity) {
        return this.customSupabaseApiUpsert(
            `/rest/v1/venue_suburbs?on_conflict=name,parent_id`,
            entity
        )
    }

    static Update(entity) {
        return this.customSupabaseApiUpdate(
            `/rest/v1/venue_suburbs?id=eq.${entity.id}&select=*`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/venue_suburbs?id=eq.${entityId}`,
            entityId
        )
    }
}
