<template>
    <div>
        <v-navigation-drawer v-model="drawer" app>
            <v-list nav dense>
                <div v-for="(link, i) in links" :key="i">
                    <!--                  :active-class="color"-->
                    <template v-if="!link.subLinks">
                        <MenuSystemItem :link="link" />
                    </template>

                    <v-list-group
                        v-else
                        :key="link.text"
                        no-action
                        :prepend-icon="link.icon"
                        :value="false"
                    >
                        <template v-slot:activator>
                            <v-list-item-title :title="link.text">
                                {{ link.text }}
                            </v-list-item-title>
                        </template>
                        <div
                            class="ml-2 pl-2"
                            style="border-left: solid 1px Gainsboro"
                        >
                            <template v-if="typeof link.subLinks == 'string'">
                                <component :is="link.subLinks"> </component>
                            </template>
                            <template v-else>
                                <template v-for="sublink in link.subLinks">
                                    <!--                                <v-list-item-->
                                    <!--                                    :to="sublink.to"-->
                                    <!--                                    :key="sublink.text"-->
                                    <!--                                    class="pl-2"-->
                                    <!--                                >-->
                                    <!--                                    <template v-if="sublink.icon.length">-->
                                    <!--                                        <v-list-item-icon>-->
                                    <!--                                            <v-icon>{{ sublink.icon }}</v-icon>-->
                                    <!--                                        </v-list-item-icon>-->
                                    <!--                                    </template>-->
                                    <!--                                    <v-list-item-title>-->
                                    <!--                                        {{ sublink.text }}-->
                                    <!--                                    </v-list-item-title>-->
                                    <!--                                </v-list-item>-->

                                    <MenuSystemItem
                                        :link="sublink"
                                        :key="sublink.text"
                                    />
                                </template>
                            </template>
                        </div>
                    </v-list-group>
                </div>

                <!--                <template-->
                <!--                    v-if="-->
                <!--                        this.$store.getters['entities/login-sessions/all']()-->
                <!--                            .length-->
                <!--                    "-->
                <!--                >-->
                <!--                    <div>-->
                <!--                        <v-list-group no-action prepend-icon="" :value="false">-->
                <!--                            <template v-slot:activator>-->
                <!--                                <v-list-item-title>-->
                <!--                                    &lt;!&ndash;                                    Enabled Groups&ndash;&gt;-->
                <!--                                    My Active Groups-->
                <!--                                </v-list-item-title>-->
                <!--                            </template>-->
                <!--                            <div-->
                <!--                                class="ml-2 pl-2"-->
                <!--                                style="border-left: solid 1px Gainsboro"-->
                <!--                            >-->
                <!--                                <v-list-item class="pl-2">-->
                <!--                                    <v-select-->
                <!--                                        label="Active Provider"-->
                <!--                                        v-model="-->
                <!--                                            $store.state.entities[-->
                <!--                                                'login-sessions'-->
                <!--                                            ].data[loginSession.access_token]-->
                <!--                                                .enabled_provider_group_id-->
                <!--                                        "-->
                <!--                                        item-text="name"-->
                <!--                                        item-value="id"-->
                <!--                                        :items="this.providerGroupsAssociatedWithUser"-->
                <!--                                    ></v-select>-->
                <!--                                </v-list-item>-->
                <!--                                <v-list-item class="pl-2">-->
                <!--                                    <v-select-->
                <!--                                        label="Active Customer"-->
                <!--                                        v-model="-->
                <!--                                            $store.state.entities[-->
                <!--                                                'login-sessions'-->
                <!--                                            ].data[loginSession.access_token]-->
                <!--                                                .enabled_customer_group_id-->
                <!--                                        "-->
                <!--                                        item-text="name"-->
                <!--                                        item-value="id"-->
                <!--                                        :items="this.customerGroupsAssociatedWithUser"-->
                <!--                                    ></v-select>-->
                <!--                                </v-list-item>-->
                <!--                            </div>-->
                <!--                        </v-list-group>-->
                <!--                    </div>-->
                <!--                </template>-->

                <!--                <template-->
                <!--                    v-if="-->
                <!--                        this.$store.getters['entities/login-sessions/all']()-->
                <!--                            .length-->
                <!--                    "-->
                <!--                >-->
                <!--                    <div>-->
                <!--                        <v-list-group no-action prepend-icon="" :value="false">-->
                <!--                            <template v-slot:activator>-->
                <!--                                <v-list-item-title>-->
                <!--                                    My Active Groups-->
                <!--                                    &lt;!&ndash;                                    My Creation Settings&ndash;&gt;-->
                <!--                                </v-list-item-title>-->
                <!--                            </template>-->
                <!--                            <div-->
                <!--                                class="ml-2 pl-2"-->
                <!--                                style="border-left: solid 1px Gainsboro"-->
                <!--                            >-->
                <!--                                <template v-if="!!loginSession">-->
                <!--                                    <MyProversAndCustomerAsSelects />-->
                <!--                                </template>-->
                <!--                            </div>-->
                <!--                        </v-list-group>-->
                <!--                    </div>-->
                <!--                </template>-->
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app>
            <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <!--        <v-toolbar-title>{{ appTitle }}</v-toolbar-title>-->
            <v-spacer></v-spacer>

            <!--            <v-btn @click="goto('/')" style="margin-right: 1em"> Home </v-btn>-->

            <!--            <EditingAndCorrespondenceSwitch />-->
            <template v-if="$store.getters['entities/login-sessions/all']()[0]">
                <v-btn @click="logout()"> Sign Out </v-btn>
            </template>
            <template v-else>
                <v-btn @click="goto('/sign-in')" style="margin-right: 1em">
                    Sign In
                </v-btn>
                <v-btn @click="goto('/join')"> Join </v-btn>
            </template>
        </v-app-bar>

        <v-main style="background-color: whitesmoke; min-height: 100vh">
            <v-container style="max-width: 1440px; position: relative">
                <slot name="main"></slot>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import LoginSession from '@/models/LoginSession'
import VueCookies from 'vue-cookies'
import DBPerson from '@/models/DBPerson'
import MenuSystemItem from '@/views/global/MenuSystemItem.vue'
import MyProversAndCustomerAsMenuList from '@/views/global/MyProversAndCustomerAsMenuList.vue'
import MyProversAndCustomerAsSelects from '@/views/global/MyProversAndCustomerAsSelects.vue'

export default {
    name: 'MenuSystem',
    components: {
        MyProversAndCustomerAsSelects,
        MyProversAndCustomerAsMenuList,
        MenuSystemItem,
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            error: '',
            loading: false,
            drawer: false,
            appTitle: 'Insert title here',
        }
    },
    methods: {
        logout() {
            VueCookies.remove('VUE_APP_AUTH')
            LoginSession.deleteAll()
        },
        seeAll() {
            this.providerId = null

            this.$router
                .push({
                    name: '/lists/providers',
                    query: {},
                })
                .catch(() => {})
        },
    },
    computed: {
        links() {
            let result = [
                {
                    icon: '',
                    text: 'Home',
                    to: '/',
                },
                {
                    icon: '',
                    text: 'Lists',
                    subLinks: [
                        {
                            icon: '',
                            text: 'Events',
                            to: '/lists/events',
                        },
                        {
                            icon: '',
                            text: 'Provider Groups',
                            to: '/lists/providers',
                        },
                        {
                            icon: '',
                            text: 'Customer Groups',
                            to: '/lists/customers',
                        },
                    ],
                },
            ]

            if (this.loginSession) {
                result.push({
                    icon: '',
                    // text: 'My Provider Groups',
                    text: 'My Shortcuts',
                    subLinks: 'MyProversAndCustomerAsMenuList',
                })
                // if (this.providerGroupsAssociatedWithUser?.length) {
                // }
                // if (this.customerGroupsAssociatedWithUser?.length) {
                //     result.push({
                //         icon: '',
                //         // text: 'My Provider Groups',
                //         text: 'My Customer Groups',
                //         subLinks: this.customerGroupsMenuSection,
                //     })
                // }
                result.push(
                    {
                        icon: '',
                        text: 'My Account',
                        subLinks: [],
                    }
                    // {
                    //     icon: '',
                    //     // text: 'Groups I\'m Representing ',
                    //     text: 'Active Groups',
                    //     subLinks: [],
                    // },
                    // ...(this.$store.getters['entities/login-sessions/all']()[0]
                    //     ? []
                    //     : [])
                )
            }

            return result
        },
        loginSession() {
            return LoginSession.query().withAllRecursive().first()
        },
    },
    watch: {
        drawer(newVal) {
            this.$emit('drawer', newVal)
        },
    },
    mounted() {
        const VUE_APP_AUTH = this.$cookies.get('VUE_APP_AUTH')
        if (VUE_APP_AUTH) {
            LoginSession.deleteAll()
            LoginSession.insert({
                data: VUE_APP_AUTH,
            })
            DBPerson.FetchAll({
                page: 1,
                limit: 1,
                filters: {
                    user_id: this.loginSession.user_id,
                },
                flags: {},
                moreHeaders: {},
                clearPrimaryModelOnly: false,
                relationships: [],
            })
        }
    },
}
</script>

<style scoped></style>
