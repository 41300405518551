// import { Model } from '@vuex-orm/core'
import DBEventType from '@/models/DBEventType'
import CustonMixins from '@/mixins/CustonMixins'
import { createClient } from '@supabase/supabase-js'
import DBProviderGroup from '@/models/DBProviderGroup'
// import Helpers from '@/utils/Helpers'
import DBBaseModel from '@/models/DBBaseModel'
import DBVenueCountry from '@/models/DBVenueCountry'
import DBVenueState from '@/models/DBVenueState'
import DBVenueSubstate from '@/models/DBVenueSubstate'
import DBVenueTown from '@/models/DBVenueTown'
import DBVenueSuburb from '@/models/DBVenueSuburb'
import DBPerson from '@/models/DBPerson'
import FieldUsageTypes from '@/utils/FieldUsageTypes'
import DBRSVP from '@/models/DBRSVP'

export default class DBEvent extends DBBaseModel {
    static entity = 'db-events'

    static getSupabaseClient() {
        const baseUrlAndHeaders =
            CustonMixins.methods.DefaultHeadersAndBaseUrl()
        const result = createClient(
            baseUrlAndHeaders.baseURL,
            // baseUrlAndHeaders.headers.Apikey,
            baseUrlAndHeaders.headers.AuthToken,
            {
                headers: { ...baseUrlAndHeaders.headers },
            }
        )
        return result
    }

    static fieldsMetadata = {
        start: { usageType: FieldUsageTypes.timeRangeStart(), important: true },
        end: { usageType: FieldUsageTypes.timeRangeEnd(), important: true },
        venue_name: {
            usageType: FieldUsageTypes.mapName(),
            important: true,
        },
        venue_address: {
            usageType: FieldUsageTypes.mapExtraFormattedAddress(),
        },
        venue_place_identifier: {
            usageType: FieldUsageTypes.mapExtraPlaceID(),
        },
        venue_longitude: {
            usageType: FieldUsageTypes.mapExtraGeoLocLong(),
            important: true,
        },
        venue_latitude: {
            usageType: FieldUsageTypes.mapExtraGeoLocLat(),
            important: true,
        },
        venue_country_id: { usageType: FieldUsageTypes.mapExtraRelCountry() },
        venue_state_id: { usageType: FieldUsageTypes.mapExtraRelAdminArea1() },
        venue_substate_id: {
            usageType: FieldUsageTypes.mapExtraRelAdminArea2(),
        },
        venue_town_id: { usageType: FieldUsageTypes.mapExtraRelLocality() },
        venue_suburb_id: {
            usageType: FieldUsageTypes.mapExtraRelSublocality(),
        },
        provider_that_owns_this_id: {
            usageType: FieldUsageTypes.ownerProviderType(),
        },
        person_that_created_this_id: {
            usageType: FieldUsageTypes.creatorType(),
        },
        rsvps: this.hasMany(DBRSVP, 'event_id'),
    }

    static fields() {
        return {
            id: this.uid(null),
            text: this.attr(''),
            start: this.attr(''),
            end: this.attr(''),
            event_type_id: this.attr(null),
            event_type: this.belongsTo(DBEventType, 'event_type_id'),

            venue_name: this.attr(null).nullable(),
            venue_address: this.attr(null).nullable(),
            venue_place_identifier: this.attr(null).nullable(),
            venue_longitude: this.attr(null).nullable(),
            venue_latitude: this.attr(null).nullable(),

            venue_country_id: this.attr(null).nullable(),
            venue_country: this.belongsTo(DBVenueCountry, 'venue_country_id'),

            venue_state_id: this.attr(null).nullable(),
            venue_state: this.belongsTo(DBVenueState, 'venue_state_id'),

            venue_substate_id: this.attr(null).nullable(),
            venue_substate: this.belongsTo(
                DBVenueSubstate,
                'venue_substate_id'
            ),

            venue_town_id: this.attr(null).nullable(),
            venue_town: this.belongsTo(DBVenueTown, 'venue_town_id'),

            // venue_suburb_1: this.attr(
            //     null
            // ).nullable(),
            // venue_suburb_2: this.attr(
            //     null
            // ).nullable(),

            venue_suburb_id: this.attr(null).nullable(),
            venue_suburb: this.belongsTo(DBVenueSuburb, 'venue_suburb_id'),

            provider_that_owns_this_id: this.attr(null),
            provider_that_owns_this: this.belongsTo(
                DBProviderGroup,
                'provider_that_owns_this_id'
            ),
            person_that_created_this_id: this.attr(null),
            person_that_created_this: this.belongsTo(
                DBPerson,
                'person_that_created_this_id'
            ),
            rsvps: this.hasMany(DBRSVP, 'event_id'),
        }
    }

    static FetchAll(
        options = {
            page: 1,
            limit: 15,
            filters: {},
            flags: {},
            moreHeaders: {},
            clearPrimaryModelOnly: false,
            relationships: [],
        }
        // { page = 1, limit = 15 },
        // filters = {},
        // flags = {},
        // relationships = [],
        // moreHeaders = {},
        // clearPrimaryModelOnly = false
    ) {
        // const supabase = this.getSupabaseClient()
        // supabase
        //     .from('events')
        //     .select('*,*', { count: 'exact'})
        //     .then((res) => {
        //         console.log(res)
        //     })

        // const test2 = supabase
        //     .from('events')
        //     .select().then().catch()
        // console.log(test2)

        // relationships
        return this.customSupabaseApiFetchAll(
            `/rest/v1/events`,
            [
                'event_type:event_type_id',
                'venue_country:venue_country_id',
                'venue_state:venue_state_id',
                'venue_substate:venue_substate_id',
                'venue_town:venue_town_id',
                'venue_suburb:venue_suburb_id',
                'provider_that_owns_this:provider_that_owns_this_id',
            ],
            options
        )
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/events?id=eq.${id}`,
            id,

            [
                'event_type:event_type_id',
                'venue_country:venue_country_id',
                'venue_state:venue_state_id',
                'venue_substate:venue_substate_id',
                'venue_town:venue_town_id',
                'venue_suburb:venue_suburb_id',
            ]
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(
            `/rest/v1/events?select=*,event_type_id(*)`,
            entity
        )
    }

    static Update(entity) {
        // const supabase = this.getSupabaseClient()
        //
        // let payload = {...entity}
        // // delete payload.id
        // // delete payload.created_at
        // supabase
        //     .from('events')
        //     .update({...payload})
        //     .eq('id', entity.id)
        //     .select()
        //     .then().catch()

        return this.customSupabaseApiUpdate(
            `/rest/v1/events?id=eq.${entity.id}&select=*,event_type_id(*)`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/events?id=eq.${entityId}`,
            entityId
        )
    }
}
