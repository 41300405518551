import DBBaseModel from '@/models/DBBaseModel'
import DBVenueState from '@/models/DBVenueState'
import CustonMixins from '@/mixins/CustonMixins'
import { createClient } from '@supabase/supabase-js'

export default class DBVenueCountry extends DBBaseModel {
    static entity = 'DBVenueCountry'

    static fields() {
        return {
            id: this.uid(null),
            name: this.attr(''),
            venue_states: this.hasMany(DBVenueState, 'parent_id'),
        }
    }

    static getSupabaseClient() {
        const baseUrlAndHeaders =
            CustonMixins.methods.DefaultHeadersAndBaseUrl()
        const result = createClient(
            baseUrlAndHeaders.baseURL,
            // baseUrlAndHeaders.headers.Apikey,
            baseUrlAndHeaders.headers.AuthToken,
            {
                headers: { ...baseUrlAndHeaders.headers },
            }
        )
        return result
    }

    static FetchAll(options) {
        return this.customSupabaseApiFetchAll(
            `/rest/v1/venue_countries`,
            [],
            options
        )
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/venue_countries?id=eq.${id}&select=*`,
            id,
            relationships
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(
            `/rest/v1/venue_countries?select=*`,
            entity
        )
    }

    static Upsert(entity) {
        // entity
        // const supabase = this.getSupabaseClient()
        // supabase
        //     .from('venue_countries')
        //     .upsert({ name: 'Albania' },{ onConflict: ['name'] })
        //     .select()
        //     .then()
        //     .catch()

        return this.customSupabaseApiUpsert(
            `/rest/v1/venue_countries?on_conflict=name`,
            entity
        )
    }

    static Update(entity) {
        return this.customSupabaseApiUpdate(
            `/rest/v1/venue_countries?id=eq.${entity.id}&select=*`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/venue_countries?id=eq.${entityId}`,
            entityId
        )
    }
}
