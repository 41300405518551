// import { Model } from '@vuex-orm/core'
// import supabase from '@/supabase.js'
import CustonMixins from '@/mixins/CustonMixins'
import { createClient } from '@supabase/supabase-js'
import DBBaseModel from '@/models/DBBaseModel'

import DBPerson from '@/models/DBPerson'
import DBCustomerManager from '@/models/DBCustomerManager'
import DBMembership from '@/models/DBMembership'
import FieldUsageTypes from '@/utils/FieldUsageTypes'
import DBRSVP from '@/models/DBRSVP'

export default class DBCustomerGroup extends DBBaseModel {
    static entity = 'DBCustomerGroup'

    static getSupabaseClient() {
        const baseUrlAndHeaders =
            CustonMixins.methods.DefaultHeadersAndBaseUrl()
        const result = createClient(
            baseUrlAndHeaders.baseURL,
            // baseUrlAndHeaders.headers.Apikey,
            baseUrlAndHeaders.headers.AuthToken,
            {
                headers: { ...baseUrlAndHeaders.headers },
            }
        )
        return result
    }

    static fieldsMetadata = {
        created_at: { usageType: FieldUsageTypes.readOnlyTimestampType() },
        person_that_created_this_id: {
            usageType: FieldUsageTypes.creatorType(),
        },
    }

    static fields() {
        return {
            id: this.uid(null),
            name: this.attr(''),
            created_at: this.attr('').nullable(),
            // events: this.hasMany(DBEvent, 'provider_that_owns_this_id'),
            person_that_created_this_id: this.attr(null),
            person_that_created_this: this.belongsTo(
                DBPerson,
                'person_that_created_this_id'
            ),
            managers: this.hasMany(
                DBCustomerManager,
                'customer_that_owns_this_id'
            ),
            memberships: this.hasMany(DBMembership, 'customer_id'),
            rsvps: this.hasMany(DBRSVP, 'customer_id'),
        }
    }

    static FetchAll(
        options = {
            page: 1,
            limit: 15,
            filters: {},
            flags: {},
            moreHeaders: {},
            clearPrimaryModelOnly: false,
            relationships: [],
        }
    ) {
        return this.customSupabaseApiFetchAll(
            `/rest/v1/customer_groups`,
            // [
            //     // 'person_that_created_this:person_that_created_this_id',
            // ],

            ['memberships(*, approved_by:approved_by_id(*))'],
            options
        )
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/customer_groups?id=eq.${id}&select=*`,
            id,
            relationships
        )
    }

    static Store(entity) {
        // const supabase = this.getSupabaseClient();
        // supabase
        //     .from('providers')
        //     .upsert(entity)
        //     .select().then().catch()
        return this.customSupabaseApiStore(
            `/rest/v1/customer_groups?select=*`,
            entity
        )
    }

    static Update(entity) {
        // const supabase = this.getSupabaseClient()
        //
        // let payload = {...entity}
        // // delete payload.id
        // // delete payload.created_at
        // supabase
        //     .from('providers')
        //     .update({...payload})
        //     .eq('id', entity.id)
        //     .select()
        //     .then().catch()
        return this.customSupabaseApiUpdate(
            `/rest/v1/customer_groups?id=eq.${entity.id}&select=*`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/customer_groups?id=eq.${entityId}`,
            entityId
        )
    }
}
