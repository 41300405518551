import DBBaseModel from '@/models/DBBaseModel'
import DBVenueState from '@/models/DBVenueState'
import DBVenueTown from '@/models/DBVenueTown'

export default class DBVenueSubstate extends DBBaseModel {
    static entity = 'DBVenueSubstate'

    static fields() {
        return {
            id: this.uid(null),
            name: this.attr(''),
            parent_id: this.attr(null),
            venue_state: this.belongsTo(DBVenueState, 'parent_id'),
            venue_towns: this.hasMany(DBVenueTown, 'parent_id'),
        }
    }

    static FetchAll(options) {
        return this.customSupabaseApiFetchAll(
            `/rest/v1/venue_substates`,
            [],
            options
        )
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/venue_substates?id=eq.${id}&select=*`,
            id,
            relationships
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(
            `/rest/v1/venue_substates?select=*`,
            entity
        )
    }

    static Upsert(entity) {
        return this.customSupabaseApiUpsert(
            `/rest/v1/venue_substates?on_conflict=name,parent_id`,
            entity
        )
    }

    static Update(entity) {
        return this.customSupabaseApiUpdate(
            `/rest/v1/venue_substates?id=eq.${entity.id}&select=*`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/venue_substates?id=eq.${entityId}`,
            entityId
        )
    }
}
