import DBBaseModel from '@/models/DBBaseModel'
import DBVenueCountry from '@/models/DBVenueCountry'
import DBVenueSubstate from '@/models/DBVenueSubstate'

export default class DBVenueState extends DBBaseModel {
    static entity = 'DBVenueState'

    static fields() {
        return {
            id: this.uid(null),
            name: this.attr(''),
            parent_id: this.attr(null),
            venue_country: this.belongsTo(DBVenueCountry, 'parent_id'),
            venue_substates: this.hasMany(DBVenueSubstate, 'parent_id'),
        }
    }

    static FetchAll(options) {
        return this.customSupabaseApiFetchAll(
            `/rest/v1/venue_states`,
            [],
            options
        )
    }

    static FetchById(id, relationships = []) {
        relationships
        return this.customSupabaseApiFetchById(
            `/rest/v1/venue_states?id=eq.${id}&select=*`,
            id,
            relationships
        )
    }

    static Store(entity) {
        return this.customSupabaseApiStore(
            `/rest/v1/venue_states?select=*`,
            entity
        )
    }

    static Upsert(entity) {
        return this.customSupabaseApiUpsert(
            `/rest/v1/venue_states?on_conflict=name,parent_id`,
            entity
        )
    }

    static Update(entity) {
        return this.customSupabaseApiUpdate(
            `/rest/v1/venue_states?id=eq.${entity.id}&select=*`,
            entity
        )
    }
    static Delete(entityId) {
        return this.customSupabaseApiDelete(
            `/rest/v1/venue_states?id=eq.${entityId}`,
            entityId
        )
    }
}
